//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Login',
    layout: 'guest',
    data() {
        return {
            email: '',
            password: '',
            isLoginInProgress: false,
        };
    },
    head() {
        return {
            title: `Holi - ${this.$t('authentication.sign_in')}`,
        };
    },
    computed: {
        rememberMe: {
            get() {
                return this.$store.state.auth.rememberMe;
            },
            set(val) {
                this.$store.commit('auth/setRememberMe', val);
            },
        },
    },
    methods: {
        onSubmit() {
            this.isLoginInProgress = true;

            this.$axios
                .$post('/auth/login', {
                    email: this.email,
                    password: this.password,
                })
                .then((res) => {
                    if (res.success === 1) {
                        this.$store.dispatch('auth/logIn', { user: res.user, token: res.token });
                        this.$router.push(
                            this.localePath('/dashboard', res.user.preferences?.language || this.$i18n.locale)
                        );
                    } else {
                        this.isLoginInProgress = false;
                        this.$refs.form.setErrors(res.error);
                    }
                })
                .catch((err) => {
                    this.isLoginInProgress = false;
                    console.log(err);
                });
        },
    },
};
